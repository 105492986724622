import React from 'react'

//Icons
import { SiSemanticscholar } from 'react-icons/si';
import { BiUserVoice } from 'react-icons/bi';
import { RiHandCoinLine } from 'react-icons/ri';
import { LiaUniversitySolid, LiaHandsHelpingSolid } from 'react-icons/lia';
import { MdOutlineAssignment } from 'react-icons/md';
import { TiTickOutline } from 'react-icons/ti';
import { TbProgressCheck } from 'react-icons/tb';
import { AiOutlineSolution } from 'react-icons/ai';
import styled from 'styled-components';


const WhyUs = () => {
    return (
        <div>
            <div className="flex flex-wrap -mx-4">
                <div className="w-full px-4">
                    <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
                        {/* <span className="block mb-2 text-lg font-semibold text-blue-900">
                                Our Services
                            </span> */}
                        <h2 className="mb-4 title">
                            Why Us?
                        </h2>
                        <p className="mb-10 subdesc">
                            Main Reasons to Choose VAS Global Consultancy
                        </p>
                    </div>
                </div>
            </div>
            <Container className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-1 lg:grid-cols-3">
                <Wrap className="p-8 border-b sm:border-r bg-orange-800 sm:flex justify-center rounded-3xl">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <TiTickOutline className='w-8 h-8 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-white">Expertise</h6>
                        <p className="mb-3 text-sm text-white leading-5">
                            Our team consists of experienced and qualified professionals with in-depth knowledge of the education systems and admission processes of various countries.
                        </p>
                    </div>
                </Wrap>
                <Wrap className="p-8 border-b lg:border-r bg-blue-700 sm:flex justify-center rounded-3xl">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <AiOutlineSolution className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-white">Customized Solutions</h6>
                        <p className="mb-3 text-sm  text-white leading-5">
                            We understand that each student is unique, and we provide personalized solutions based on their individual needs and requirements.
                        </p>
                    </div>
                </Wrap>
                <Wrap className="p-8 border-b sm:border-r lg:border-r-0 bg-orange-800 sm:flex justify-center rounded-3xl">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <TbProgressCheck className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-white">Transparency</h6>
                        <p className="mb-3 text-sm text-white leading-5">
                            We maintain complete transparency throughout the process, and our students are regularly updated about the progress of their application.
                        </p>
                    </div>
                </Wrap>
                <Wrap className="p-8 border-b lg:border-r bg-blue-700 sm:flex justify-center rounded-3xl">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <RiHandCoinLine className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5  text-white">Affordable Services</h6>
                        <p className="mb-3 text-sm  text-white leading-5">
                            We believe that everyone deserves quality education and we offer the lowest fee packages.
                        </p>
                    </div>
                </Wrap>
                <Wrap className="p-8 border-b sm:border-r bg-orange-800 sm:flex justify-center rounded-3xl">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <LiaUniversitySolid className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5  text-white">University Selection</h6>
                        <p className="mb-3 text-sm text-white leading-5">
                            We help students select the right course, college or university, and country based on their academic profile, interests, and budget.
                        </p>
                    </div>
                </Wrap>

                <Wrap className="p-8 border-b bg-blue-700 sm:flex justify-center rounded-3xl">
                    <div className="max-w-md text-center ">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <LiaHandsHelpingSolid className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-white">Assistance Guidance</h6>
                        <p className="mb-3 text-sm text-white leading-5">
                            We assist students in the entire application process, including filling out application forms, writing SOPs, essays, and letters of recommendation etc.
                        </p>
                    </div>

                </Wrap>

                <Wrap className="p-8 border-b sm:border-r lg:border-b-0 bg-orange-800 sm:flex justify-center rounded-3xl">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <MdOutlineAssignment className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-white">Visa Assistance</h6>
                        <p className="mb-3 text-sm text-white leading-5">
                            We guide students through the visa process, including preparing the required documents, scheduling visa workshops, and providing guidance on visa interview preparation.
                        </p>
                    </div>
                </Wrap>

                <Wrap className="p-8 border-b lg:border-b-0 lg:border-r bg-blue-700 sm:flex justify-center rounded-3xl">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <BiUserVoice className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-white">Pre-Departure Briefing</h6>
                        <p className="mb-3 text-sm text-white leading-5">
                            We provide students with a comprehensive briefing on what to expect once they reach their destination country, including information on accommodation, travel, and orientation.
                        </p>
                    </div>
                </Wrap>

                <Wrap className="p-8 sm:border-r lg:border-r-0 bg-orange-800 sm:flex justify-center rounded-3xl">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <SiSemanticscholar className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-white">Scholarship Assistance</h6>
                        <p className="mb-3 text-sm text-white leading-5">
                            We provide information on available scholarships and assist students in applying.
                        </p>
                    </div>
                </Wrap>
            </Container>
        </div>
    )
}

export default WhyUs


const Container = styled.div`
margin-top: 30px;
display: grid;
padding: 30px 0px 26px;
grid-gap: 25px;
grid-template-columns: repeat(3, minmax(0, 1fr));

@media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

const Wrap = styled.div`
border-radius: 10px;
cursor: pointer;
border: 3px solid rgba(249, 249, 249, 0.1);
box-shadow: rgb(0 0 0 /69%) 0px 26px 30px -10px,
rgb(0 0 0/ 73%) 0px 16px 10px -10px;
transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

img {
    width:100%;
    height: 100%;
    object-fit: cover;
}

&:hover {
 box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
 rgb(0 0 0 / 72%) 0px 30px 22px -10px;   
 transform: scale(1.05);
 border-color: rgba(249, 249, 249, 0.8);
}
`