import React from 'react'

const ProgramBenefits = () => {
    return (
        <div className="flex flex-col mx-auto space-y-12" id='details'>
            <div className="relative">
                <h2 className="w-full title text-center">Discover Benefits</h2>
                <p className="w-full py-8 mx-auto -mt-2 subdesc text-center sm:max-w-3xl">Some important points to understand the significance of associating with VAS Global Consultancy and how you can benefit with it.</p>
            </div>
            <div className="animated fadeIn">
                <div className="mt-5">
                    <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Economical Study Options</h3>
                    <p className="mt-5 mb-10 pb-10 border-b-2 text">VAS Global Consultancy believes in giving students an advantage when it comes to affordable study options. They understand the financial challenges students face and strive to provide cost-effective solutions. VAS Global Consultancy aims to give students the advantage of affordable study options through low-cost study programs, scholarships, grants, and financial aid opportunities to deserving students, making higher education more accessible and affordable. They understand that education can be expensive, so they strive to provide budget-friendly solutions for students. Suppose a student comes from a family with limited financial resources. VAS Global Consultancy can help him/ her explore cost-effective programs, scholarships, grants, and other financial aid options to make their education more affordable, allowing them to pursue their dreams without worrying too much about the cost and also not compromising on the university standards.
                    </p>
                    <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Comprehensive Programs and Resources</h3>
                    <p className="mt-5 mb-10 pb-10 border-b-2 text">VAS Global Consultancy offers comprehensive programs and resources designed to give students a competitive advantage in the ever-evolving academic and professional landscape. VAS Global Consultancy provides online courses, mentorship programs, and access to a vast library of educational materials, enabling students to stay updated with the latest knowledge and trends in their fields. They provide support in various aspects, such as test preparation, career counseling, skill development, and more. Whether you need help with SAT/IELTS/ GRE/ GMAT preparation, guidance in choosing the right career path, or assistance in developing interview skills, VAS Global Consultancy has programs and resources tailored to your needs. These resources ensure that you have the tools necessary to succeed academically and professionally.
                    </p>
                    <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Excellent Job Opportunities</h3>
                    <p className="mt-5 mb-10 pb-10 border-b-2 text">VAS Global Consultancy opens doors to excellent job opportunities for students. They have strong connections with industry leaders and provide students with the necessary skills and resources to succeed in the professional world. VAS Global Consultancy organizes job fairs, internships, online interviews and career development workshops where students can connect with potential employers and explore various career paths. They understand the evolving professional landscape and provide students with the necessary skills and resources to stand out in the job market. VAS Global Consultancy can offer you internships, workshops, and networking opportunities with industry professionals. These experiences can enhance your resume and help you secure a desirable job during and after the study program.
                    </p>
                    <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Best University Admissions</h3>
                    <p className="mt-5 mb-10 pb-10 border-b-2 text">VAS Global Consultancy aims to secure the best university admissions for its students. They have the expertise and knowledge to guide students through the application process and increase your chances of getting accepted into top-tier universities. VAS Global Consultancy provides personalized assistance in preparing college applications, writing impactful essays, and conducting mock interviews to help students stand out and gain admission to their dream universities. If you aspire to study medicine, VAS Global Consultancy will assist you in preparing for entrance exams, writing compelling personal statements, and acquiring the necessary recommendation letters, helping build your profile etc. Their guidance can significantly improve your chances of getting accepted into prestigious medical schools.
                    </p>
                    <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Guaranteed Outcomes</h3>
                    <p className="mt-5 mb-10 pb-10 border-b-2 text">VAS Global Consultancy is committed to ensuring guaranteed outcomes for students. This means that they work tirelessly to help students achieve their goals and have a successful and fulfilling career. VAS Global Consultancy offers specialized training programs that equip students with the necessary skills and knowledge to secure high-paying jobs in their chosen fields. Let's say you want to pursue a career in engineering. By joining VAS Global Consultancy, you will get all the necessary guidance and support to secure admission to a top engineering university and increase your chances of landing a good job in the field.
                    </p>
                    <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Maximum Benefits</h3>
                    <p className="mt-5 pb-10 text">VAS Global Consultancy is dedicated to providing students with the maximum benefits they need to excel. This means that students can expect valuable resources, support, and guidance that will enhance their educational journey. By joining VAS Global Consultancy, students gain access to budget-friendly study programs, exclusive study materials, personalized counseling, and networking opportunities with industry professionals.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ProgramBenefits