import React from "react";
// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import {
  AtSymbolIcon,
  PhoneArrowUpRightIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/20/solid";
import testprep from "../assets/testprep.jpg";
import styled from "styled-components";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import study from '../assets/testprep/studymaterials.jpg'
import analytic from '../assets/testprep/analytic.jpg'
import expert from '../assets/testprep/expert.jpg'
import classroom from '../assets/testprep/classroom.jpeg'


function Studyprep() {
  const [text] = useTypewriter({
    words: ["Ultimate Study Destination"],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });
  return (
    <>
      <div className="relative isolate overflow-hidden bg-white">
        <div className="absolute inset-0 -z-10 overflow-hidden" style={{height:'53vh'}}>
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <Wrap>
          <div className="flex justify-around mt-6 mb-2 w-full relative">
            <img
              className="w-1/2"
              src={testprep}
              alt=""
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0px",
                left: "0px",
                zIndex: "-1",
                border: "2px solid white",
                borderRadius: "21px",
              }}
            />
            <div className="relative flex justify-center align-middle isolate px-10 p-6 pt-4 lg:px-8">
              <div className="mx-auto lg:max-w-3xl">
                <div className="text-center">
                  <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-2xl lg:text-5xl 2xl:text-5xl">
                    Navigate to Global Opportunities With Our: <br />
                  </h1>
                  <span className="text-4xl font-semibold tracking-tight sm:text-2xl lg:text-5xl 2xl:text-5xl text-blue-900">
                    {text} <Cursor cursorStyle="/" />
                  </span>
                  <p className="flex justify-center mt-6 text-sm text-white font-medium lg:text-base">
                    <span className="w-4/6">
                      Embark on a transformative educational journey with the
                      assistance of our abroad study consultancy.
                    </span>
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/connect" className="btn">
                      Contact Us
                    </Link>
                    <Link
                      to="/programs"
                      className="text-sm font-semibold flex items-center gap-1.5 leading-6 text-white hover:text-white duration-300 transition-colors"
                    >
                      Our Services
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrap>

        <Container className="mx-auto md:max-w-xl lg:max-w-full grid gap-8 grid-cols-2 lg:grid-cols-2">
          <Wraper className="flex flex-col mb-2 animated fadeIn sm:flex-col m-8 p-6">
            <div className="flex h-60 items-center sm:w-full md:w-full" >
              <img
                className="rounded-lg shadow-xl"
                src={study}
                alt="Commitments"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
              Comprehensive and Exhaustive Study Material
              </h3>
            </div>
          </Wraper>
          <Wraper className="flex flex-col mb-2 animated fadeIn sm:flex-col m-8 p-6">
            <div className="flex h-60 items-center sm:w-full md:w-full">
              <img
                className="rounded-lg shadow-xl"
                src={analytic}
                alt="Commitments"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
              Intensive Classroom Training Programs
              </h3>
            </div>
          </Wraper>
          <Wraper className="flex flex-col mb-2 animated fadeIn sm:flex-col m-8 p-6">
            <div className="flex h-60 items-center sm:w-full md:w-full">
              <img
                className="rounded-lg shadow-xl"
                src={expert}
                alt="Commitments"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
              Training Sessions by Experienced Faculty
              </h3>
            </div>
          </Wraper>
          <Wraper className="flex flex-col mb-2 animated fadeIn sm:flex-col m-8 p-6">
            <div className="flex h-60 items-center sm:w-full md:w-full">
              <img
                className="rounded-lg shadow-xl"
                src={classroom}
                alt="Commitments"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
              Verbal, Quantitative and Analytical Training Sessions
              </h3>
            </div>
          </Wraper>
        </Container>
      </div>

      {/* Contact Info */}
   
    </>
  );
}

export default Studyprep;

const Wrap = styled.div`
  cursor: pointer;
  img {
    border: 4px solid trnsparent;
    border-radius: 4px;
    width: 50%;
    height: 60vh;
    transition-duration: 300ms;
    &:hover {
      border: 4px solid rgba(249, 249, 249, 0.8);
    }
  }
  @media (max-width: 768px) {
    img {
      height: 28vh;
    }
  }
  @media (max-width: 1900px) {
    img {
      height: 32vh;
    }
  }
`;

const Container = styled.div`
  margin-top: 30px;
  display: grid;
  padding: 30px 0px 26px;
  grid-gap: 25px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Wraper = styled.div`
  border-radius: 10px;
  cursor: pointer;
  border: 3px solid rgba(249, 249, 249, 0.1);
  box-shadow: rgb(0 0 0 /69%) 0px 26px 30px -10px,
    rgb(0 0 0/ 73%) 0px 16px 10px -10px;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    transform: scale(1.05);
    border-color: rgba(249, 249, 249, 0.8);
  }
`;
