/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

//images
import brandimg from "../assets/brandlogo.png";
import { FaAngleRight, FaArrowRight } from "react-icons/fa";
import {
  AtSymbolIcon,
  PhoneArrowUpRightIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/20/solid";
import { TbDeviceLandlinePhone } from "react-icons/tb";
//icons
import {
  RiInstagramFill,
  RiFacebookFill,
  RiTwitterFill,
  RiYoutubeFill,
} from "react-icons/ri";



const Footer = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    // <div className="px-4 pt-16 mx-auto sm:max-w-2xl md:max-w-full lg:max-w-full md:px-24 lg:px-8">
    <div>
      <div className="p-6 mx-auto max-w-sm sm:max-w-2xl md:max-w-full lg:max-w-full border-t-2 border-gray-300">
        <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-2">
          <div className="md:max-w-md lg:col-span-1">
            <Link
              to="/"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <img className="w-60 h-20" src={brandimg} alt="brandlogo" />

              {/* <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 ">
                            VAS Global Consultancy
                        </span> */}
            </Link>
            <div className="mt-4 lg:max-w-sm">
              <p className="text-sm text-gray-700 leading-6">
                Welcome to VAS Global Consultancy International Education
                Consultancy, your trusted partner in the journey towards higher
                education in the world's top universities.
              </p>
            </div>
          </div>
          <div className="grid gap-5 row-gap-8 lg:grid-cols-2 sm:grid-cols-1">
            <div className="sm:col-span-1">
              <p className="font-semibold tracking-wide text-gray-800">
                Quick Links
              </p>
              <ul className="mt-2 space-y-2">
                <li>
                  <Link
                    to="/about"
                    className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/programs"
                    className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/studydestination"
                    className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                  >
                    Study Destinations
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    className="text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-gray-500"
                  >
                    Educational Loans
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <p className="font-semibold text-lg tracking-wide text-gray-800">
                Contact Us
              </p>
              <ul className="mt-2">
                <li style={{ display: "flex" }} className='mb-4 items-center'>
                  <div className="flex justify-center items-center w-8 h-8 rounded-full bg-gray-200 lg:h-8 lg:w-8 shadow-xl">
                    <AtSymbolIcon className="w-4 text-gray-600" />
                  </div>
                  <p className="text-gray-600 w-52 sm:w-fit ml-4">
                    vasglobalconsultancy9@gmail.com
                  </p>
                </li>
                <li style={{ display: "flex" }} className='mb-4 items-center'>
                  <div className="flex justify-center items-center w-8 h-8 rounded-full bg-gray-200 lg:h-8 lg:w-8 shadow-xl">
                    <PhoneArrowUpRightIcon className="w-4 text-gray-600" />
                  </div>
                  <p className="text-gray-600 ml-4">+91 7075199795</p>
                </li>
                <li style={{ display: "flex" }} className='mb-4 items-center'>
                  <div className="flex justify-center items-center w-8 h-8 rounded-full bg-gray-200 lg:h-8 lg:w-8 shadow-xl">
                    <TbDeviceLandlinePhone className="w-4 text-gray-600" />
                  </div>
                  <p className="text-gray-600 ml-4">0891-4426057</p>
                </li>
                
                <li style={{ display: "flex" }} className='mb-4 items-center'>
                  <div className="flex justify-center items-center w-8 h-8 rounded-full bg-gray-200 lg:h-8 lg:w-8 shadow-xl">
                    <BuildingOfficeIcon className="w-4 text-gray-600" />
                  </div>
                  <p className="text-gray-600 w-52 sm:w-fit ml-4">
                    D.No.50-81-55, 3rd Floor Seethammapeta Main Road,
                    Visakhapatnam - 530016
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between pt-5 pb-5 border-t sm:flex-row bg-orange-800 sm:items-center">
        <p className="text-sm text-white ml-6 md:ml-0 flex justify-center">
          © Copyright 2023 VAS Global Consultancy. All rights reserved.
        </p>
        <div className="flex items-center justify-center mt-4 space-x-4 md:mt-0 mb-4">
          {/* <Link
                        className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    >
                        <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                            <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                        </svg>
                    </Link>
                    <Link
                        className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    >
                        <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                            <circle cx="15" cy="15" r="4" />
                            <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                        </svg>
                    </Link>
                    <Link
                        className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    >
                        <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                            <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                        </svg>
                    </Link> */}
          <a href="https://www.facebook.com/profile.php?id=61555866716767" className="text-white hover:text-[#3b5998] cursor-pointer text-2xl duration-300 transition-colors">
            <RiFacebookFill />
          </a>
          <a href="" className="ml-4 text-white hover:text-[#26a7de] cursor-pointer text-2xl duration-300 transition-colors">
            <RiTwitterFill />
          </a>
          <a href="https://www.instagram.com/vasglobalconsultancy/" className="ml-4 text-white hover:text-[#e2457a] cursor-pointer text-2xl duration-300 transition-colors">
            <RiInstagramFill />
          </a>
          <a href="" className="ml-4 text-white hover:text-[#CD201F] cursor-pointer text-2xl duration-300 transition-colors">
            <RiYoutubeFill />
          </a>
        </div>
        <p className="text-sm text-white mr-6 md:mr-0 sm:mt-3 flex justify-center">
          Developed by @Innovix Technologies.
        </p>
      </div>
    </div>
  );
};

export default Footer;
