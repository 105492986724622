import React from 'react'

//Icons
import { PiStudent } from 'react-icons/pi';
import { SlBadge } from 'react-icons/sl';
import { LuVerified } from 'react-icons/lu';
import { AiOutlineStar } from 'react-icons/ai';
import {styled} from 'styled-components'

const Stats = () => {
    return (
        <Container className="mx-auto md:max-w-xl lg:max-w-full grid gap-8 grid-cols-2 lg:grid-cols-4">
            <Wrap className="text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-cyan-50 shadow-md">
                    <PiStudent className="w-8 h-8 sm:w-10 sm:h-10 text-cyan-800" />
                </div>
                <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-cyan-800">25000+</h6>
                <p className="text-sm mb-2 font-semibold text-gray-700">Student Counselled</p>
            </Wrap>
            <Wrap className="text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-indigo-50 shadow-md">
                    <SlBadge className="w-8 h-8 sm:w-10 sm:h-10 text-indigo-400" />
                </div>
                <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-indigo-400">3000+</h6>
                <p className="text-sm mb-2 font-semibold text-gray-700">Placed Successfully</p>
            </Wrap>
            <Wrap className="text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-green-50 shadow-md">
                    <LuVerified className="w-8 h-8 sm:w-10 sm:h-10 text-green-700" />
                </div>
                <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-green-700">99%</h6>
                <p className="text-sm mb-2 font-semibold text-gray-700">Visa Success Rate</p>
            </Wrap>
            <Wrap className="text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-orange-50 shadow-md">
                    <AiOutlineStar className="w-8 h-8 sm:w-10 sm:h-10 text-orange-900" />
                </div>
                <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-orange-900">20+</h6>
                <p className="text-sm mb-2 font-semibold text-gray-700">Years of Experience</p>
            </Wrap>
        </Container>
    )
}

export default Stats

const Container = styled.div`
margin-top: 30px;
display: grid;
padding: 30px 0px 26px;
grid-gap: 25px;
grid-template-columns: repeat(4, minmax(0, 1fr));

@media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

const Wrap = styled.div`
border-radius: 10px;
cursor: pointer;
border: 3px solid rgba(249, 249, 249, 0.1);
box-shadow: rgb(0 0 0 /69%) 0px 26px 30px -10px,
rgb(0 0 0/ 73%) 0px 16px 10px -10px;
transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

img {
    width:100%;
    height: 100%;
    object-fit: cover;
}

&:hover {
 box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
 rgb(0 0 0 / 72%) 0px 30px 22px -10px;   
 transform: scale(1.05);
 border-color: rgba(249, 249, 249, 0.8);
}
`