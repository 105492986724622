import React from 'react'
import Header from '../components/Header';

//components
import Stats from '../components/Stats';
import Features from '../components/Features';
import WhyUs from '../components/WhyUs';
import Banner from '../components/Banner';

const Home = () => {
  return (
    <div className='relative isolate overflow-hidden bg-white space-y-40'>
      <Header />
      <Stats />
      <Features />
      <WhyUs />
      <Banner />
    </div>
  )
}

export default Home