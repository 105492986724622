import React from "react";
import { Link } from "react-router-dom";

//icons
import { FaArrowRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styled from "styled-components";
import slide1 from "../assets/slide1.jpeg";
import slide2 from "../assets/slide2.jpg";
import slide3 from "../assets/slide3.jpeg";
import slide4 from "../assets/slide4.jpeg";


const Header = () => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div>
      {/* <div className="carasol" style={{position:"relative", width:"100%", height:"64vh", minHeight:"40vh"}}>
      <video
          loop
          muted
          autoPlay
          infinite
          id="bgvideo"
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: "-1",
            border: "2px solid white",
            borderRadius: "21px"
          }}
        >
          <source src="/images/globebackground.mp4" type="video/mp4" />
        </video>
       
        <div className="relative isolate px-10 pt-14 lg:px-8" style={{zIndex:"1"}}>
        
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto lg:max-w-2xl">
            <div className="hidden md:mb-8 md:flex md:justify-center">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-white ring-1 ring-white-900/10 hover:ring-white-900/20">
                WELCOME TO VAS CONSULTANCY SERVICES.{" "}
                <Link to="/about" className="font-semibold text-white">
                  <span className="absolute inset-0" aria-hidden="true" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
            <div className="text-center">
              <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                Create Pathways for Students to{" "}
                <span className="text-blue-900">Study Abroad.</span>
              </h1>
              <p className="flex justify-center mt-6 text-sm text-white font-medium lg:text-base">
                <span className="w-4/6">
                  We Think About You & Strive to Deliver the Best Because You
                  Deserve the Best.
                </span>
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link to="/connect" className="btn">
                  Connect
                </Link>
                <Link
                  to="/programs"
                  className="text-sm font-semibold flex items-center gap-1.5 leading-6 text-white hover:text-white  duration-300 transition-colors"
                >
                  Our Services
                  <FaArrowRight />
                </Link>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
      </div> */}
      <Carousel {...settings}>
        <Wrap>
          <div
            className="carasol"
            style={{
              backgroundImage: `url(${slide1})`, height:"72vh", borderRadius:"2px"
            }}
          >
            <div className="relative isolate px-10 pt-14 lg:px-8">
              <div className="mx-auto lg:max-w-4xl">
                <div className="hidden md:mb-8 md:flex md:justify-center">
                  <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-white font-semibold ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    WELCOME TO VAS GLOBAL CONSULTANCY.{" "}
                    <Link to="/about" className="font-semibold text-white">
                      <span className="absolute inset-0 font-semibold" aria-hidden="true" />
                      Read more <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </div>
                </div>
                <div className="text-center">
                  <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                    Create Pathways for Students to{" "}
                    <span className="text-blue-800" >Study Abroad.</span>
                  </h1>
                  <p className="flex justify-center mt-6 text-sm text-white font-medium lg:text-base">
                    <span className="w-4/6">
                      We Think About You & Strive to Deliver the Best Because
                      You Deserve the Best.
                    </span>
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/connect" className="btn">
                      Connect
                    </Link>
                    <Link
                      to="/programs"
                      className="text-sm font-semibold flex items-center gap-1.5 leading-6 text-white hover:text-blue-500  duration-300 transition-colors"
                    >
                      Our Programs
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrap>
        <Wrap>
          <div
            className="carasol"
            style={{
              backgroundImage: `url(${slide2})`, height:"72vh", borderRadius:"2px"
            }}
          >
            <div className="relative isolate px-10 pt-14 lg:px-8">
             
              <div className="mx-auto lg:max-w-4xl">
                <div className="hidden md:mb-8 md:flex md:justify-center">
                  <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-white font-semibold ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    WELCOME TO VAS GLOBAL CONSULTANCY.{" "}
                    <Link to="/about" className="font-semibold text-white">
                      <span className="absolute inset-0 font-semibold" aria-hidden="true" />
                      Read more <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </div>
                </div>
                <div className="text-center">
                  <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl" >
                     Forge Global Academic Journeys for Students to {" "}
                    <span className="text-blue-800">Study Abroad.</span>
                  </h1>
                  <p className="flex justify-center mt-6 text-sm text-white font-medium lg:text-base">
                    <span className="w-4/6">
                    "Unlock Global Opportunities: Your Pathway to Success with our Study Abroad Consultancy!"
                    </span>
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/connect" className="btn">
                      Connect
                    </Link>
                    <Link
                      to="/programs"
                      className="text-sm font-semibold flex items-center gap-1.5 leading-6 text-white hover:text-blue-500  duration-300 transition-colors"
                    >
                      Our Programs
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </Wrap>
        <Wrap>
          <div
            className="carasol"
            style={{
              backgroundImage: `url(${slide3})`, height:"72vh", borderRadius:"2px"
            }}
          >
            <div className="relative isolate px-10 pt-14 lg:px-8">
              
              <div className="mx-auto lg:max-w-4xl">
                <div className="hidden md:mb-8 md:flex md:justify-center">
                  <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-white font-semibold ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    WELCOME TO VAS GLOBAL CONSULTANCY.{" "}
                    <Link to="/about" className="font-semibold text-white">
                      <span className="absolute inset-0 font-semibold" aria-hidden="true" />
                      Read more <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </div>
                </div>
                <div className="text-center">
                  <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl" >
                    Crafting Opportunities for Students to {" "}
                    <span className="text-blue-800" >Study Abroad.</span>
                  </h1>
                  <p className="flex justify-center mt-6 text-sm text-white font-medium lg:text-base">
                    <span className="w-4/6">
                    "Navigate Your Educational Journey: Expert Guidance for Studying Abroad and Achieving Your Academic Dreams."
                    </span>
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/connect" className="btn">
                      Connect
                    </Link>
                    <Link
                      to="/programs"
                      className="text-sm font-semibold flex items-center gap-1.5 leading-6 text-white hover:text-blue-500  duration-300 transition-colors"
                    >
                      Our Programs
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </Wrap>
        <Wrap>
          <div
            className="carasol"
            style={{
              backgroundImage: `url(${slide4})`, height:"72vh", borderRadius:"2px"
            }}
          >
            <div className="relative isolate px-10 pt-14 lg:px-8">
              
              <div className="mx-auto lg:max-w-4xl">
                <div className="hidden md:mb-8 md:flex md:justify-center">
                  <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-white font-semibold ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    WELCOME TO VAS GLOBAL CONSULTANCY.{" "}
                    <Link to="/about" className="font-semibold text-white">
                      <span className="absolute inset-0 font-semibold" aria-hidden="true" />
                      Read more <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </div>
                </div>
                <div className="text-center">
                  <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl" >
                  Building Bridges to{" "}
                    <span className="text-blue-800" >Study Abroad.</span>{" "}Excellence for Students.
                  </h1>
                  <p className="flex justify-center mt-6 text-sm text-white font-medium lg:text-base">
                    <span className="w-4/6">
                    "Empowering Futures Worldwide: Elevate Your Education with Our Comprehensive Study Abroad Consultancy Services."
                    </span>
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/connect" className="btn">
                      Connect
                    </Link>
                    <Link
                      to="/programs"
                      className="text-sm font-semibold flex items-center gap-1.5 leading-6 text-white hover:text-blue-500  duration-300 transition-colors"
                    >
                      Our Programs
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </Wrap>
      </Carousel>
    </div>
  );
};

export default Header;

const Carousel = styled(Slider)`
  ul li button {
    &:before {
      font-size: 10px;
      color: rgb(150, 158, 171);
    }
  }

  li.slick-active button:before {
    color: white;
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-slide {
    position: relative;
  }

  button {
    z-index: 1;
  }
`;

const Wrap = styled.div`
  cursor: pointer;
  img {
    border: 4px solid trnsparent;
    border-radius: 4px;
    width: 50%;
    height: 60vh;
    transition-duration: 25s;
    &:hover {
      border: 4px solid rgba(249, 249, 249, 0.8);
    }
  }
  @media (max-width: 768px) {
    img {
      height: 28vh;
    }
  }
  @media (max-width: 1900px) {
    img {
      height: 32vh;
    }
  }
  .carasol {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    place-items: center;
  }
`;
