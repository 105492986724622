/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react'

import { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//icons
import { RiInstagramFill, RiFacebookFill, RiTwitterFill, RiYoutubeFill } from 'react-icons/ri';
import emailjs from '@emailjs/browser';
import logo from '../assets/brandlogo.png'
import styled, { keyframes, css } from 'styled-components';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Connect = () => {
    const [agreed, setAgreed] = useState(false);

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_uas6521', 'template_z2ciqwj', form.current, 'Df-uRiVfVLWCEkdJ8')
      .then((result) => {
          console.log(result.text);
          console.log('email sent');
          showToastMessage();
      }, (error) => {
          console.log(error.text);
          showErrorMessage();
      });
  };

  const showToastMessage = () => {
    toast.success('Successfully Sent Email!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
  };
  const showErrorMessage = () => {
    toast.error('Error While Sending Email!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
  };

    return (
        // <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8"> --original
        <div className="isolate mt-16 mb-36 p-6 mx-auto max-w-sm sm:max-w-2xl md:max-w-full lg:max-w-full">
          

            {/* <div role="main" className="flex flex-col items-center justify-center">
                <h1 className="text-4xl font-semibold tracking-tight text-center text-blue-900 sm:text-5xl">News & Blogs</h1>
                <p className="text-sm lg:text-base font-medium text-center text-gray-600 mt-4 lg:w-1/2 md:w-10/12 w-11/12">Stay updated with our news and blog for valuable insights and resources. Join our community and access the latest trends and opportunities in education.</p>
            </div> */}

<Wrapper>
        <Marquee className='max-w-xl m-auto mb-10'>
          <MarqueeGroup>
            <span>WelCome To VAS Global Consultancy. Fill the Contact Form, We will Get Back To you Soon.</span>
          </MarqueeGroup>
        </Marquee>
      </Wrapper>

            {/* <div className="mx-auto max-w-2xl text-center mt-28 "> --original */}
            <div className="mx-auto text-center mt-16">
                <h2 className="title">Contact Us</h2>
                <p className="mt-2 subdesc">
                    We are here to assist you on your study abroad journey.
                </p>
            </div>
            <form ref={form} onSubmit={sendEmail} className="mx-auto mt-16 max-w-xl sm:mt-20">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-600">
                            First name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="firstname"
                                id="first-name"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-600">
                            Last name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="lastname"
                                id="last-name"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-600">
                            Email
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-600">
                            Phone number
                        </label>
                        <div className="relative mt-2.5">
                            <div className="absolute inset-y-0 left-0 flex items-center">
                                <label htmlFor="country" className="sr-only">
                                    Country
                                </label>
                                <select
                                    id="country"
                                    name="country"
                                    className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                >
                                    <option>+91</option>
                                </select>
                            </div>
                            <input
                                type="phonenumber"
                                name="phonenumber"
                                id="phone-number"
                                autoComplete="tel"
                                className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-600">
                            Message
                        </label>
                        <div className="mt-2.5">
                            <textarea
                                name="message"
                                id="message"
                                rows={4}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                defaultValue={''}
                            />
                        </div>
                    </div>
                    <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2 justify-center">
                        <div className="flex h-6 items-center">
                            <Switch
                                checked={agreed}
                                onChange={setAgreed}
                                className={classNames(
                                    agreed ? 'bg-blue-700' : 'bg-gray-200',
                                    'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                )}
                            >
                                <span className="sr-only">Agree to policies</span>
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        agreed ? 'translate-x-3.5' : 'translate-x-0',
                                        'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                                    )}
                                />
                            </Switch>
                        </div>
                        <Switch.Label className="text-sm leading-6 text-gray-700">
                            By selecting this, you agree to allow us to contact you via phone
                        </Switch.Label>
                    </Switch.Group>
                </div>
                <div className="mt-10">
                    <button
                        type="submit"
                        className="block w-full rounded-xl bg-blue-700 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-xl hover:bg-blue-500 transform transition duration-500 hover:scale-110 focus:bg-blue-500"
                    >
                        Let's talk
                    </button>
                </div>
                <ToastContainer />
             
            </form>

            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                <a href="mailto:vasglobalconsultancy9@gmail.com" className="text-blue-900 font-semibold hover:text-gray-500 duration-300 transition-colors">vasglobalconsultancy9@gmail.com</a>
                <p className="leading-normal my-5 text-gray-700 ">
                    <a href="https://goo.gl/maps/Rko9qVf1rBpS9qe2A" className='hover:text-gray-500 duration-300 transition-colors'>
                        D.No.50-81-55, 3rd Floor Seethammapeta Main Road, Visakhapatnam - 530016
                    </a>
                </p>
                <span className="inline-flex">
                    <a href="https://www.facebook.com/profile.php?id=61555866716767" className="text-gray-500 hover:text-[#3b5998] cursor-pointer text-xl duration-300 transition-colors">
                        <RiFacebookFill />
                    </a>
                    <a href='' className="ml-4 text-gray-500 hover:text-[#26a7de] cursor-pointer text-xl duration-300 transition-colors">
                        <RiTwitterFill />
                    </a>
                    <a href="https://www.instagram.com/vasglobalconsultancy/" className="ml-4 text-gray-500 hover:text-[#e2457a] cursor-pointer text-xl duration-300 transition-colors">
                        <RiInstagramFill />
                    </a>
                    <a href='' className="ml-4 text-gray-500 hover:text-[#CD201F] cursor-pointer text-xl duration-300 transition-colors">
                        <RiYoutubeFill />
                    </a>
                </span>
            </div>

        </div>
    )
}

export default Connect

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Marquee = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;


const scrollX = keyframes`
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(-200%);
  }
`;

const scrollXmb = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 10s linear infinite;
  @media (max-width: 768px) {
    width: fit-content;
    animation: ${scrollXmb} 10s linear infinite;
  }
`;

const MarqueeGroup = styled.div`
  ${common}

  span{
    font-size: 36px;
    font-weight: 900;
    color: #ff7800;
  }
  @media (max-width: 768px) {
    span{
        font-size: 24px;
        font-weight: 900;
        color: #ff7800;
      }
  }

`;