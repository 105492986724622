import React from "react";
// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import {
  AtSymbolIcon,
  PhoneArrowUpRightIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/20/solid";
import studydestinations from "../assets/studydestinations.jpg";
import styled from "styled-components";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import usa from "../assets/studydestination/usa.jpeg";
import uk from "../assets/studydestination/uk.jpg";
import canada from "../assets/studydestination/canada.jpg";
import ireland from "../assets/studydestination/ireland.jpeg";
import austrelia from "../assets/studydestination/ireland.jpeg";
import italy from "../assets/studydestination/italy.jpeg";
import newzealand from "../assets/studydestination/newzealand.jpeg";

function StudyDestination() {
  const [text] = useTypewriter({
    words: ["Ultimate Study Destination"],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });
  return (
    <>
      <div className="relative isolate overflow-hidden bg-white">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <Wrap>
          <div className="flex justify-around mt-6 mb-2 w-full" style={{height:'53vh'}}>
            <img className="w-1/2" src={studydestinations} alt="" style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: "-1",
            border: "2px solid white",
            borderRadius: "21px"
          }}/>
            <div className="relative flex justify-center align-middle isolate px-10 p-6 pt-4 lg:px-8">
              <div className="mx-auto lg:max-w-3xl">
                <div className="text-center">
                  <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-2xl lg:text-5xl 2xl:text-5xl">
                    Navigate to Global Opportunities With Our: <br />
                  </h1>
                  <span className="text-4xl font-semibold tracking-tight sm:text-2xl lg:text-5xl 2xl:text-5xl text-blue-900">
                    {text} <Cursor cursorStyle="/" />
                  </span>
                  <p className="flex justify-center mt-6 text-sm text-white font-medium lg:text-base">
                    <span className="w-4/6">
                      Embark on a transformative educational journey with the
                      assistance of our abroad study consultancy.
                    </span>
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/connect" className="btn">
                    Contact Us
                    </Link>
                    <Link
                      to="/programs"
                      className="text-sm font-semibold flex items-center gap-1.5 leading-6 text-white hover:text-white  duration-300 transition-colors"
                    >
                      Our Services
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrap>
      </div>

      {/* About */}
      <section className="py-40 bg-white">
        <div className="flex flex-col px-2 mx-auto space-y-12 max-w-full ml-4 mr-4 xl:px-2">
          <div className="relative">
            {/* mt-2 text-3xl font-bold tracking-tight text-blue-900 sm:text-4xl */}
            <h2 className="w-full text-3xl font-bold text-center sm:text-4xl text-blue-900">
              Our Study Destination
            </h2>
          </div>
          <SDWrap className="flex flex-col mb-10 animated fadeIn sm:flex-row m-36 p-6">
            <div className="flex items-center sm:w-1/2 md:w-5/12 sm:order-last">
              <img
                className="rounded-lg shadow-xl"
                src={usa}
                alt="Commitments"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">Drag-n-drop design</p> */}
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Why Study in USA?
              </h3>
              <p className="mt-5 text-base text-gray-700 sm:text-left lg:text-lg">
                The United States stands as a beacon of educational excellence,
                hosting world-renowned universities that cater to diverse
                academic pursuits. 🎓 Boasting a vast array of programs,
                students can explore cutting-edge research, innovation, and a
                myriad of disciplines, from STEM to the arts. 🌐 Cultural
                diversity thrives on U.S. campuses, providing a rich environment
                for global perspectives and cross-cultural interactions.
              </p>
            </div>
          </SDWrap>
          <SDWrap className="flex flex-col mb-10 animated fadeIn sm:flex-row m-36 p-6">
            <div className="flex items-center sm:w-1/2 md:w-5/12">
              <img
                className="rounded-lg shadow-xl"
                src={canada}
                alt="Objective"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
              {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">know your data</p> */}
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Why Study in Canada?
              </h3>
              <p className="mt-5 text-base text-gray-700 sm:text-left lg:text-lg">
                🍁 Canada stands as a welcoming and inclusive study destination,
                renowned for its high-quality education and breathtaking natural
                landscapes. 🎓 With a focus on innovation and research, Canadian
                universities offer diverse programs in fields such as
                technology, engineering, arts, and business. 🌐 Embrace a
                multicultural environment, where students from around the world
                come together to share experiences and perspectives, fostering a
                global mindset.
              </p>
            </div>
          </SDWrap>
          <SDWrap className="flex flex-col mb-10 animated fadeIn sm:flex-row m-36 p-6">
            <div className="flex items-center sm:w-1/2 md:w-5/12 sm:order-last">
              <img
                className="rounded-lg shadow-xl"
                src={austrelia}
                alt="Commitments"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">Drag-n-drop design</p> */}
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Why Study in Australia?
              </h3>
              <p className="mt-5 text-base text-gray-700 sm:text-left lg:text-lg">
                🐨 Australia, renowned for its vibrant cities and unique
                wildlife, offers a world-class education system that attracts
                students from across the globe. 🎓 Explore a diverse range of
                programs in leading universities, known for their academic
                excellence and cutting-edge research initiatives. 🌏 Embrace the
                multicultural atmosphere, as Australia welcomes students with
                open arms, fostering an environment of inclusivity and cultural
                exchange.
              </p>
            </div>
          </SDWrap>
          <SDWrap className="flex flex-col mb-10 animated fadeIn sm:flex-row m-36 p-6">
            <div className="flex items-center sm:w-1/2 md:w-5/12">
              <img className="rounded-lg shadow-xl" src={uk} alt="Objective" />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
              {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">know your data</p> */}
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Why Study in UK?
              </h3>
              <p className="mt-5 text-base text-gray-700 sm:text-left lg:text-lg">
                The United Kingdom, steeped in history and cultural richness,
                beckons students with a globally recognized education system. 🎓
                Explore a myriad of programs in prestigious universities, known
                for their academic rigor, research contributions, and a
                tradition of excellence. 🌍 Immerse yourself in the diverse and
                inclusive British culture, as the UK attracts students from
                around the world, fostering a global community.
              </p>
            </div>
          </SDWrap>
          <SDWrap className="flex flex-col mb-10 animated fadeIn sm:flex-row m-36 p-6">
            <div className="flex items-center sm:w-1/2 md:w-5/12 sm:order-last">
              <img
                className="rounded-lg shadow-xl"
                src={italy}
                alt="Commitments"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">Drag-n-drop design</p> */}
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Why Study in Italy?
              </h3>
              <p className="mt-5 text-base text-gray-700 sm:text-left lg:text-lg">
                Italy, a cradle of art, history, and culinary delights, beckons
                students with a unique blend of tradition and modernity in its
                education system. 🎓 Explore a diverse range of academic
                programs in historic universities, renowned for their
                contributions to art, science, and humanities. 🌍 Immerse
                yourself in the rich cultural tapestry of Italy, where ancient
                ruins coexist with vibrant modern cities, creating an inspiring
                backdrop for learning.
              </p>
            </div>
          </SDWrap>
          <SDWrap className="flex flex-col mb-10 animated fadeIn sm:flex-row m-36 p-6">
            <div className="flex items-center sm:w-1/2 md:w-5/12">
              <img
                className="rounded-lg shadow-xl"
                src={ireland}
                alt="Objective"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
              {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">know your data</p> */}
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Why Study in Ireland?
              </h3>
              <p className="mt-5 text-base text-gray-700 sm:text-left lg:text-lg">
                🍀 Ireland, known for its friendly people and breathtaking
                landscapes, invites students to experience a world-class
                education in a welcoming and inclusive environment. 🎓 Explore a
                wide range of academic programs in esteemed universities, where
                a strong emphasis on research and innovation contributes to
                global advancements. 🌍 Immerse yourself in the vibrant Irish
                culture, characterized by music, literature, and a strong sense
                of community, creating a nurturing atmosphere for academic
                growth.
              </p>
            </div>
          </SDWrap>
          <SDWrap className="flex flex-col mb-10 animated fadeIn sm:flex-row m-36 p-6">
            <div className="flex items-center sm:w-1/2 md:w-5/12 sm:order-last">
              <img
                className="rounded-lg shadow-xl"
                src={newzealand}
                alt="Student Benefits"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">Easy to customize</p> */}
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Why Study in New Zealand?
              </h3>
              <p className="mt-5 text-base text-gray-700 sm:text-left lg:text-lg">
                New Zealand, known for its stunning landscapes and warm
                hospitality, beckons students with a world-class education
                system in a picturesque setting. 🎓 Explore a diverse range of
                academic programs in renowned universities, where a focus on
                innovation and research fosters an environment of academic
                excellence. 🌏 Immerse yourself in the Kiwi way of life,
                characterized by a laid-back atmosphere and a strong connection
                to nature, creating a unique and enriching student experience.
              </p>
            </div>
          </SDWrap>
        </div>
      </section>

      {/* Contact Info */}
      {/* <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-full sm:py-16 lg:px-6">
          <div className="mx-auto mb-8 lg:mb-16 text-center">
     

            <h2 className="mb-4 text-3xl tracking-tight font-bold text-blue-900 sm:text-4xl">
              Contact Us
            </h2>
            <p className="text-gray-600 font-medium text-base">
              Get guidance on country selection, intake, deadlines, tests, and
              eligibility criteria.
            </p>
          </div>
          <div className="space-y-8 flex flex-col items-start justify-center sm:grid sm:grid-cols-3 sm:gap-5 lg:gap-12 sm:space-y-0 text-center">
            <div className="flex flex-col items-center">
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-gray-200 lg:h-12 lg:w-12 shadow-xl">
                <AtSymbolIcon className="w-6 text-gray-600" />
              </div>
              <h3 className="mb-2 font-bold text-gray-800 ">Email</h3>
              <p className="text-gray-600 w-52 sm:w-fit">
                vasglobalconsultancy9@gmail.com
              </p>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-gray-200 lg:h-12 lg:w-12 shadow-xl">
                <PhoneArrowUpRightIcon className="w-6 text-gray-600" />
              </div>
              <h3 className="mb-2 font-bold text-gray-800">Phone</h3>
              <p className="text-gray-600">
                +91 7660837407
              </p>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-gray-200 lg:h-12 lg:w-12 shadow-xl">
                <BuildingOfficeIcon className="w-6 text-gray-600" />
              </div>
              <h3 className="mb-2 font-bold text-gray-800">Address</h3>
              <p className="text-gray-600 w-52 sm:w-fit">
                D.No.50-81-55, 3rd Floor Seethammapeta Main Road, Visakhapatnam - 530016
              </p>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default StudyDestination;

const Wrap = styled.div`
  cursor: pointer;
  img {
    border: 4px solid trnsparent;
    border-radius: 4px;
    width: 50%;
    height: 60vh;
    transition-duration: 300ms;
    &:hover {
      border: 4px solid rgba(249, 249, 249, 0.8);
    }
  }
  @media (max-width: 768px) {
    img {
      height: 28vh;
    }
  }
  @media (max-width: 1900px) {
    img {
      height: 32vh;
    }
  }
`;

const SDWrap = styled.div`
  border-radius: 10px;
  cursor: pointer;
  border: 3px solid rgba(249, 249, 249, 0.1);
  box-shadow: rgb(0 0 0 /69%) 0px 26px 30px -10px,
    rgb(0 0 0/ 73%) 0px 16px 10px -10px;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    transform: scale(1.05);
    border-color: rgba(249, 249, 249, 0.8);
  }
  @media (max-width: 768px) {
   margin: 5px;
  }
`;
