import React from "react";
// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import {
  AtSymbolIcon,
  PhoneArrowUpRightIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/20/solid";
import educationalloans from "../assets/educationalLoans.jpg";
import styled, { keyframes, css } from "styled-components";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import eduback from "../assets/mo.jpg";

const arrowstyle = {
  '@media (max-width: 768)': {
    width: "250px", height: "50px",
    },
}


function EducationalLoans() {
  const row1 = [
    "/images/educationalloans/1.png",
    "/images/educationalloans/2.png",
    "/images/educationalloans/3.png",
    "/images/educationalloans/4.png",
    "/images/educationalloans/5.png",
    "/images/educationalloans/6.png",
    "/images/educationalloans/7.png",
  ];

  const row2 = [
    "/images/educationalloans/8.png",
    "/images/educationalloans/9.png",
    "/images/educationalloans/10.png",
    "/images/educationalloans/11.png",
    "/images/educationalloans/12.png",
    "/images/educationalloans/13.png",
    "/images/educationalloans/14.png",
    "/images/educationalloans/15.png",
  ];
  const [text] = useTypewriter({
    words: ["Abroad Study"],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });

  return (
    <>
      <div className="relative isolate overflow-hidden bg-white">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <Wrap>
          <div className="flex justify-around mt-6 mb-2 w-full" style={{height:'53vh'}}>
            <img className="w-1/2" src={educationalloans} alt="" style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: "-1",
            border: "2px solid white",
            borderRadius: "21px",
          }}/>
            <div className="relative flex justify-center align-middle isolate px-10 p-6 pt-4 lg:px-8">
              <div className="mx-auto lg:max-w-3xl">
                <div className="text-center">
                  <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-2xl lg:text-5xl 2xl:text-5xl">
                    Navigate Educational Loans for <br />
                  </h1>
                  <span className="text-4xl font-semibold tracking-tight sm:text-2xl lg:text-5xl 2xl:text-5xl text-blue-900">
                    {text} <Cursor cursorStyle="/" />
                  </span>
                  <p className="flex justify-center mt-6 text-sm text-white font-medium lg:text-base">
                    <span className="w-4/6">
                      Empower your educational dreams with our consultancy's
                      tailored guidance on securing educational loans.
                    </span>
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/connect" className="btn">
                      Contact Us
                    </Link>
                    <Link
                      to="/programs"
                      className="text-sm font-semibold flex items-center gap-1.5 leading-6 text-white hover:text-white  duration-300 transition-colors"
                    >
                      Our Services
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrap>
      </div>

      {/* About */}
      <section className="py-40 bg-white">
        <div
          className="flex flex-col px-2 mx-auto space-y-12 max-w-full ml-4 mr-4 xl:px-2"
          style={{
            backgroundImage: `url(${eduback})`,
            backgroundSize: "cover",
            height: "100%",
          }}
        >
          <div className="relative">
            {/* mt-2 text-3xl font-bold tracking-tight text-blue-900 sm:text-4xl */}
            <h2 className="w-full text-3xl font-bold text-center sm:text-4xl text-blue-900">
              Loan Process
            </h2>
            <p className="w-full py-8 mx-auto -mt-2 text-base text-center text-gray-700 font-medium sm:max-w-3xl">
              Add some nice touches to your interface with our latest designs,
              components, and templates. We've crafted a beautiful user
              experience that your visitors will love.{" "}
            </p>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div
              className="relative sm:w-96 lg:w-1/2"
              style={{height: "100px", background: "red", display:"flex", justifyContent:"center", alignItems:"center"}}
            >
              <div
                className="absolute left-0 bottom-0 w-0 h-0 border-white"
                style={{
                  borderLeft: "25px solid #fff",
                  borderTop: "50px solid transparent",
                  borderBottom: "50px solid transparent",
                }}
              ></div>
              <div
                className="absolute right-[-25px] bottom-[-20px] w-0 h-0 border-gray-300"
                style={{
                  borderLeft: "25px solid red",
                  borderTop: "70px solid transparent",
                  borderBottom: "70px solid transparent",
                }}
              ></div>
              <h1 className="text-white lg:text-4xl sm:text-2xl font-extrabold flex justify-center text-center">
                Approach Us in Person
              </h1>
            </div>
          </div>
          <div
            className="flex flex-col mb-10 animated fadeIn sm:flex-row"
            style={{ flexDirection: "row-reverse" }}
          >
            <div
              className="relative"
              style={{ width: "880px", height: "100px", background: "blue", display:"flex", justifyContent:"center", alignItems:"center" }}
            >
              <div
                className="absolute w-0 h-0 border-white"
                style={{
                  borderLeft: "25px solid blue",
                  bottom: "-20px",
                  left: "-25px",
                  borderTop: "70px solid transparent",
                  borderBottom: "70px solid transparent",
                  rotate: "180deg",
                }}
              ></div>
              <div
                className="absolute w-0 h-0 border-gray-300"
                style={{
                  borderLeft: "25px solid #fff",
                  right: "0px",
                  bottom: "0px",
                  borderTop: "50px solid transparent",
                  borderBottom: "50px solid transparent",
                  rotate: "180deg",
                }}
              ></div>
              <h1 className="text-white lg:text-4xl sm:text-2xl m-5 font-extrabold flex justify-center text-center">
                Create Your Profile and Know Your Eligibility
              </h1>
            </div>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div
              className="relative sm:w-96 lg:w-1/2"
              style={{ height: "100px", background: "orange", display:"flex", justifyContent:"center", alignItems:"center" }}
            >
              <div
                className="absolute left-0 bottom-0 w-0 h-0 border-white"
                style={{
                  borderLeft: "25px solid #fff",
                  borderTop: "50px solid transparent",
                  borderBottom: "50px solid transparent",
                }}
              ></div>
              <div
                className="absolute right-[-25px] bottom-[-20px] w-0 h-0 border-gray-300"
                style={{
                  borderLeft: "25px solid orange",
                  borderTop: "70px solid transparent",
                  borderBottom: "70px solid transparent",
                }}
              ></div>
              <h1 className="text-white lg:text-4xl sm:text-2xl m-5 font-extrabold flex justify-center text-center">
                Proceed With Documentation & Application
              </h1>
            </div>
          </div>
          <div
            className="flex flex-col mb-10 animated fadeIn sm:flex-row"
            style={{ flexDirection: "row-reverse" }}
          >
            <div
              className="relative"
              style={{ width: "880px", height: "100px", background: "green", display:"flex", justifyContent:"center", alignItems:"center" }}
            >
              <div
                className="absolute left-0 bottom-0 w-0 h-0 border-white"
                style={{
                  borderLeft: "25px solid green",
                  bottom: "-20px",
                  left: "-25px",
                  borderTop: "70px solid transparent",
                  borderBottom: "70px solid transparent",
                  rotate: "180deg",
                }}
              ></div>
              <div
                className="absolute right-[-25px] bottom-[-20px] w-0 h-0 border-gray-300"
                style={{
                  borderLeft: "25px solid #fff",
                  right: "0px",
                  bottom: "0px",
                  borderTop: "50px solid transparent",
                  borderBottom: "50px solid transparent",
                  rotate: "180deg",
                }}
              ></div>
              <h1 className="text-white lg:text-4xl sm:text-2xl font-extrabold flex justify-center text-center">
                Get Your Loan Sanctioned
              </h1>
            </div>
          </div>
        </div>
      </section>

      <Wrapper>
        <Text>With Great Outcomes.</Text>
        <Note>Our customers have gotten offers from awesome companies.</Note>
        <Marquee>
          <MarqueeGroup>
            {row1.map((el)=> (
              <ImageGroup>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {row1.map((el) => (
              <ImageGroup>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee>
        <Marquee>
          <MarqueeGroup2>
            {row2.map((el) => (
              <ImageGroup>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup2>
          <MarqueeGroup2>
            {row2.map((el) => (
              <ImageGroup>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup2>
        </Marquee>
      </Wrapper>

    
    </>
  );
}

export default EducationalLoans;

const Wrap = styled.div`
  cursor: pointer;
  img {
    border: 4px solid trnsparent;
    border-radius: 4px;
    width: 50%;
    height: 60vh;
    transition-duration: 300ms;
    &:hover {
      border: 4px solid rgba(249, 249, 249, 0.8);
    }
  }
  @media (max-width: 768px) {
    img {
      height: 28vh;
    }
  }
  @media (max-width: 1900px) {
    img {
      height: 32vh;
    }
  }
`;

const Arrow = styled.div`
  width: 250px;
  height: 50px;
  background: red;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid #fff;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
  }
  &:before {
    content: "";
    position: absolute;
    right: -25px;
    bottom: 0;
    height: 0;
    border-left: 25px solid #ccc;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
  }
`;


const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Text = styled.div`
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #02203c;
`;

const Note = styled.div`
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 40px;
  color: #7c8e9a;
`;

const Marquee = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 30s linear infinite;
  @media (max-width: 768px) {
    width: fit-content;
  }
`;

const MarqueeGroup = styled.div`
  ${common}
`;
const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 50);
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  padding: 5px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;
