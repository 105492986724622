import React from "react";
// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import {
  AtSymbolIcon,
  PhoneArrowUpRightIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/20/solid";
import aboutus from "../assets/aboutus.jpg";
import styled from "styled-components";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const About = () => {
  const [text] = useTypewriter({
    words: ["VAS GLOBAL CONSULTANCY"],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });
  return (
    <>
      <div className="relative isolate overflow-hidden bg-white">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <Wrap>
          <div className="flex justify-around mt-6 mb-2 w-full relative h-1/3" style={{height:'53vh'}} >
            <img className="w-1/2" src={aboutus} alt=""  style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: "-1",
            border: "2px solid white",
            borderRadius: "21px",
          }} />
            <div className="relative flex justify-center align-middle isolate px-10 p-6 lg:px-8">
              <div className="mx-auto lg:max-w-3xl">
                
                <div className="text-center">
                  <h1 className="text-4xl font-semibold tracking-tight text-white sm:text-2xl lg:text-5xl 2xl:text-5xl">
                    Know More About{" "}
                    <br/>
                  </h1>
                  <span className="text-4xl font-semibold tracking-tight sm:text-2xl lg:text-5xl 2xl:text-5xl text-blue-900">{text} <Cursor cursorStyle='/' /></span>
                  <p className="flex justify-center mt-6 text-sm text-white font-medium lg:text-base">
                    <span className="w-4/6">
                    Trusted international education consultants since 2023, working for Student success.
                    </span>
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/connect" className="btn">
                    Contact Us
                    </Link>
                    <Link
                      to="/programs"
                      className="text-sm font-semibold flex items-center gap-1.5 leading-6 text-white hover:text-white duration-300 transition-colors"
                    >
                      Our Services
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrap>
      </div>

      {/* About */}
      <section className="py-40 bg-white">
        <div className="flex flex-col px-2 mx-auto space-y-12 max-w-full ml-4 mr-4 xl:px-2">
          <div className="relative">
            {/* mt-2 text-3xl font-bold tracking-tight text-blue-900 sm:text-4xl */}
            <h2 className="w-full text-3xl font-bold text-center sm:text-4xl text-blue-900">
              About Us
            </h2>
            <p className="w-full py-8 mx-auto -mt-2 text-base text-center text-gray-700 font-medium sm:max-w-3xl">
              Add some nice touches to your interface with our latest designs,
              components, and templates. We've crafted a beautiful user
              experience that your visitors will love.{" "}
            </p>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12 sm:order-last">
              <img
                className="rounded-lg shadow-xl"
                src="https://dl.dropboxusercontent.com/scl/fi/2xgwdibh2g91woux2fxe5/commitments.jpg?rlkey=9t77lclo7cvwspq2g41hj8k02&dl=0"
                alt="Commitments"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">Drag-n-drop design</p> */}
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Our Commitments
              </h3>
              <p className="mt-5 text-base text-gray-700 sm:text-left lg:text-lg">
                We are dedicated to offering affordable study programs that
                bring value to students who dream of pursuing their academic
                goals in various countries. Our commitment lies in providing
                opportunities for education that are accessible and enriching,
                empowering students to fulfill their aspirations and unlock
                their potential on an international scale.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12">
              <img
                className="rounded-lg shadow-xl"
                src="https://dl.dropboxusercontent.com/scl/fi/gjye4ck4gsu1h6ykd5yj9/objective.jpg?rlkey=4efckjkjbdx4xrrtjkaudtvb5&dl=0"
                alt="Objective"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
              {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">know your data</p> */}
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Our Objective
              </h3>
              <p className="mt-5 text-base text-gray-700 sm:text-left lg:text-lg">
                All along our objective at VAS Global Consultancy has been to
                benefit the Indian student community. “We Work for YOUR benefits
                only” being our sole motto behind our carefully picked
                affordable programs along with comprehensive and personalised
                guidance is to ensure best education with cost-effectiveness to
                help you make your dream of studying abroad a reality.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12 sm:order-last">
              <img
                className="rounded-lg shadow-xl"
                src="https://dl.dropboxusercontent.com/scl/fi/o7utsbd4uuljdpwq5juu1/benefits.jpg?rlkey=ykxlj2hbqxk7pv711ok1lhzzx&dl=0"
                alt="Student Benefits"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">Easy to customize</p> */}
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Work for your Benefit
              </h3>
              <p className="mt-5 text-base text-gray-700 sm:text-left lg:text-lg">
                Whether you are looking for ranked university admissions after
                12th, or affordable study programs or scholarships or may be
                internships during bachelors program or after that or a good
                Masters/ PhD program with excellent job opportunities, look no
                further, VAS Global Consultancy has covered you all. VAS Global
                Consultancy is an organisation that focuses on helping students
                succeed in their academic and professional lives. It works
                diligently for your profitability. At VAS Global Consultancy,
                our motto is to give students the advantage of economical study
                and career pathways, secure the best university admissions, and
                open doors to excellent job opportunities. In short, we are here
                only to bring you benefits!
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Programs Benefit */}
      <section className="py-16 bg-white">
        <div className="flex flex-col px-2 mx-auto space-y-12 max-w-full ml-4 mr-4 xl:px-2">
          <div className="relative">
            <h2 className="w-full text-3xl font-bold text-center sm:text-4xl text-blue-900">
              Discover Benefits
            </h2>
            <p className="w-full py-8 mx-auto -mt-2 text-base text-center text-gray-600 font-medium sm:max-w-3xl">
              Some important points to understand the significance of
              associating with VAS Global Consultancy and how you can benefit
              with it.
            </p>
          </div>
          <div className="mb-10 animated fadeIn">
            <div className="mt-5">
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Economical Study Options
              </h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-gray-600 text sm:text-left lg:text-lg lg:text-justify">
                VAS Global Consultancy believes in giving students an advantage
                when it comes to affordable study options. They understand the
                financial challenges students face and strive to provide
                cost-effective solutions. VAS Global Consultancy aims to give
                students the advantage of affordable study options through
                low-cost study programs, scholarships, grants, and financial aid
                opportunities to deserving students, making higher education
                more accessible and affordable. They understand that education
                can be expensive, so they strive to provide budget-friendly
                solutions for students. Suppose a student comes from a family
                with limited financial resources. VAS Global Consultancy can
                help him/ her explore cost-effective programs, scholarships,
                grants, and other financial aid options to make their education
                more affordable, allowing them to pursue their dreams without
                worrying too much about the cost and also not compromising on
                the university standards.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Comprehensive Programs and Resources
              </h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-gray-600 text sm:text-left lg:text-lg lg:text-justify">
                VAS Global Consultancy offers comprehensive programs and
                resources designed to give students a competitive advantage in
                the ever-evolving academic and professional landscape. VAS
                Global Consultancy provides online courses, mentorship programs,
                and access to a vast library of educational materials, enabling
                students to stay updated with the latest knowledge and trends in
                their fields. They provide support in various aspects, such as
                test preparation, career counseling, skill development, and
                more. Whether you need help with SAT/IELTS/ GRE/ GMAT
                preparation, guidance in choosing the right career path, or
                assistance in developing interview skills, VAS Global
                Consultancy has programs and resources tailored to your needs.
                These resources ensure that you have the tools necessary to
                succeed academically and professionally.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Excellent Job Opportunities
              </h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-gray-600 text sm:text-left lg:text-lg lg:text-justify">
                VAS Global Consultancy opens doors to excellent job
                opportunities for students. They have strong connections with
                industry leaders and provide students with the necessary skills
                and resources to succeed in the professional world. VAS Global
                Consultancy organizes job fairs, internships, online interviews
                and career development workshops where students can connect with
                potential employers and explore various career paths. They
                understand the evolving professional landscape and provide
                students with the necessary skills and resources to stand out in
                the job market. VAS Global Consultancy can offer you
                internships, workshops, and networking opportunities with
                industry professionals. These experiences can enhance your
                resume and help you secure a desirable job during and after the
                study program.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Best University Admissions
              </h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-gray-600 text sm:text-left lg:text-lg lg:text-justify">
                VAS Global Consultancy aims to secure the best university
                admissions for its students. They have the expertise and
                knowledge to guide students through the application process and
                increase your chances of getting accepted into top-tier
                universities. VAS Global Consultancy provides personalized
                assistance in preparing college applications, writing impactful
                essays, and conducting mock interviews to help students stand
                out and gain admission to their dream universities. If you
                aspire to study medicine, VAS Global Consultancy will assist you
                in preparing for entrance exams, writing compelling personal
                statements, and acquiring the necessary recommendation letters,
                helping build your profile etc. Their guidance can significantly
                improve your chances of getting accepted into prestigious
                medical schools.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Guaranteed Outcomes
              </h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-gray-600 text sm:text-left lg:text-lg lg:text-justify">
                VAS Global Consultancy is committed to ensuring guaranteed
                outcomes for students. This means that they work tirelessly to
                help students achieve their goals and have a successful and
                fulfilling career. VAS Global Consultancy offers specialized
                training programs that equip students with the necessary skills
                and knowledge to secure high-paying jobs in their chosen fields.
                Let's say you want to pursue a career in engineering. By joining
                VAS Global Consultancy, you will get all the necessary guidance
                and support to secure admission to a top engineering university
                and increase your chances of landing a good job in the field.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">
                Maximum Benefits
              </h3>
              <p className="mt-5 mb-10 pb-10 text-base text-gray-600 text sm:text-left lg:text-lg lg:text-justify">
                VAS Global Consultancy is dedicated to providing students with
                the maximum benefits they need to excel. This means that
                students can expect valuable resources, support, and guidance
                that will enhance their educational journey. By joining VAS
                Global Consultancy, students gain access to budget-friendly
                study programs, exclusive study materials, personalized
                counseling, and networking opportunities with industry
                professionals.
              </p>
            </div>
          </div>
        </div>
      </section>

      
    </>
  );
};

export default About;

const Wrap = styled.div`
  cursor: pointer;
  img {
    border: 4px solid trnsparent;
    border-radius: 4px;
    width: 50%;
    height: 60vh;
    transition-duration: 300ms;
    &:hover {
      border: 4px solid rgba(249, 249, 249, 0.8);
    }
  }
  @media (max-width: 768px) {
    img {
      height: 28vh;
    }
  }
  @media (max-width: 1900px) {
    img {
      height: 32vh;
    }
  }
`;
